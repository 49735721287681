export const menuItems = [
    {
        name: 'about-us',
        label: 'About Us',
    },
    {
        name: 'https://empglabs.breezy.hr/',
        label: 'Careers',
        isExternal: true,
    },
    // {
    //     name: 'our-team',
    //     label: 'Our Team',
    // },
    {
        name: 'contact-us',
        label: 'Contact Us',
    },
];
